import React from "react";
import axios from "axios";
import {
    Alert,
    Button
} from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

import InstallationDetailAddProductModal from "./modal/InstallationDetailAddProductModal";
import InstallationDetailRemoveProductModal from "./modal/InstallationDetailRemoveProductModal";
import InstallationProductCard from "./components/InstallationProductCard";
import InstallationTakenProductCard from "./components/InstallationTakenProductCard";
import InstallationDetailTakeProductModal from "./modal/InstallationDetailTakeProductModal";
import InstallationDetailUndoTakeProductModal from "./modal/InstallationDetailUndoTakeProductModal";
import ParentSummary from "../../../components/ParentSummary";
import InstallationDetailReplaceProductModal from "./modal/InstallationDetailReplaceProductModal";
import InstallationDetailHistoryModal from "./modal/InstallationDetailProductsHistoryModal";
import InstallationDetailImportProductsModal from "./modal/InstallationDetailImportProductsModal";
import EuroFormatter from "../../../components/formatters/EuroFormatter";

class InstallationDetailProducts extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            reservedProducts: null,
            takenProducts: null,
            wasteFeeTotal: null,
            error: null,

            showHistoryModal: false,

            showAddProductsModal: false,

            showImportProductsModal: false,

            showRemoveProductsModal: false,
            removeProductModalProduct: null,

            showReplaceProductModal: false,
            replaceProductModalProduct: null,

            showTakeProductsModal: false,
            takeProductModalProduct: null,

            showUndoTakenProductsModal: false,
            undoTakenProductModalProduct: null
        };
        this.setProductsState = this.setProductsState.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.showAddProductModal = this.showAddProductModal.bind(this);
    }

    componentDidMount() {
        this.getProducts();
        document.addEventListener("keydown", this.onKeyDown);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.onKeyDown);
    }

    onKeyDown(event) {
        if(event.keyCode === 78) { // N key
            if(this.showAddProductModal()) {
                event.preventDefault();
            }
        }
    }

    showAddProductModal() {
        const {
            error,
            reservedProducts,
            takenProducts,
            showAddProductsModal,
            showReplaceProductModal
        } = this.state;

        if(error) {
            return false;
        }
        if(!reservedProducts || !takenProducts) {
            return false;
        }
        if(showAddProductsModal || showReplaceProductModal) {
            return false;
        }
        this.setState({ showAddProductsModal: true });
        return true;
    }

    getProducts() {
        this.setState({ error: null });
        axios.post("/getInstallationProducts", { installationId: this.props.installationId })
            .then((response) => {
                if(response.data.valid) {
                    this.setProductsState(response.data.reserved, response.data.taken, true);
                } else {
                    this.setState({ error: "Er ging iets fout. Probeer het later opnieuw." });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ error: "Er ging iets fout. Probeer het later opnieuw." });
            });
    }

    calculateWasteFeeTotal(products) {
        let wasteFeeTotal = 0.0;
        products.forEach((product) => {
            if(!product.wasteFeeTotal) {
                return;
            }
            wasteFeeTotal += product.installationAmount * product.wasteFeeTotal;
        });
        return wasteFeeTotal;
    }

    setProductsState(reserved, taken, initialLoad = false) {
        if(!initialLoad) {
            let refresh = false;
            if(this.state.reservedProducts && this.state.takenProducts) {
                if(this.state.reservedProducts.length === 0 && Object.keys(this.state.takenProducts).length === 0) {
                    this.props.refreshInstallation();
                    refresh = true;
                }
            }
            if(!refresh && reserved.length === 0 && taken.length === 0) {
                this.props.refreshInstallation();
            }
        }

        this.setState({
            reservedProducts: reserved
        });

        let groupedProducts = {};
        for(const takenProduct of taken) {
            if(!(takenProduct.id in groupedProducts)) {
                groupedProducts[takenProduct.id] = [];
            }
            groupedProducts[takenProduct.id].push(takenProduct);
        }
        const takenProducts = Object.values(groupedProducts).sort((takenProducts1, takenProducts2) => {
            const product1 = takenProducts1[0];
            const product2 = takenProducts2[0];
            if(product1.type.priority > product2.type.priority) return 1;
            if(product1.type.priority < product2.type.priority) return -1;
            if(product1.brand.id > product2.brand.id) return 1;
            if(product1.brand.id < product2.brand.id) return -1;
            if(product1.name > product2.name) return 1;
            if(product1.name < product2.name) return -1;
            return 0;
        })
        this.setState({
            takenProducts,
            wasteFeeTotal: this.calculateWasteFeeTotal([...reserved, ...taken])
        });
    }

    render() {
        const {
            error,
            reservedProducts,
            takenProducts,
            wasteFeeTotal
        } = this.state;
        if(error) {
            return (
                <Alert variant="danger">{ error }</Alert>
            );
        }
        if(reservedProducts === null || takenProducts === null) {
            return (
                <React.Fragment>
                    {[...Array(20)].map((value, index) => (
                        <div className="mb-3" key={ index }>
                            <Skeleton height={ 80 }/>
                        </div>
                    ))}
                </React.Fragment>
            )
        }

        return (
            <React.Fragment>
                <InstallationDetailHistoryModal
                    show={ this.state.showHistoryModal }
                    handleClose={ () => this.setState({ showHistoryModal: false }) }
                    installationId={ this.props.installationId }
                />
                <InstallationDetailAddProductModal
                    show={ this.state.showAddProductsModal }
                    existingProducts={ this.state.reservedProducts }
                    handleClose={ () => this.setState({ showAddProductsModal: false }) }
                    onSave={ this.setProductsState }
                    installationId={ this.props.installationId }
                />
                <InstallationDetailImportProductsModal
                    show={ this.state.showImportProductsModal }
                    existingProducts={ this.state.reservedProducts }
                    handleClose={ () => this.setState({ showImportProductsModal: false }) }
                    onSave={ this.setProductsState }
                    installationId={ this.props.installationId }
                />
                <InstallationDetailRemoveProductModal
                    show={ this.state.showRemoveProductsModal }
                    product={ this.state.removeProductModalProduct }
                    installationId={ this.props.installationId }
                    handleClose={ () => this.setState({ showRemoveProductsModal: false }) }
                    onRemove={ this.setProductsState }
                />
                <InstallationDetailReplaceProductModal
                    show={ this.state.showReplaceProductModal }
                    handleClose={ () => this.setState({ showReplaceProductModal: false }) }
                    installationProduct={ this.state.replaceProductModalProduct }
                    existingProducts={ this.state.reservedProducts }
                    onSave={ this.setProductsState }
                />
                <InstallationDetailTakeProductModal
                    show={ this.state.showTakeProductsModal }
                    product={ this.state.takeProductModalProduct }
                    installationId={ this.props.installationId }
                    handleClose={ () => this.setState({ showTakeProductsModal: false }) }
                    onTaken={ this.setProductsState }
                />
                <InstallationDetailUndoTakeProductModal
                    show={ this.state.showUndoTakenProductsModal }
                    takenProduct={ this.state.undoTakenProductModalProduct }
                    handleClose={ () => this.setState({ showUndoTakenProductsModal: false }) }
                    onUndo={ this.setProductsState }
                />

                <ParentSummary
                    installation={ this.props.installation }
                />

                { reservedProducts.length === 0 && takenProducts.length === 0 ? (
                    <div className="text-center">
                        <h1><i className="fas fa-warehouse"/></h1>
                        <h3>Geen materiaal</h3>
                        <p>Er is nog geen materiaal toegevoegd aan deze installatie.</p>
                        <p className="d-none d-lg-block">
                            <b>Tip!</b> Gebruik
                            <kbd className="mx-2">
                                N
                            </kbd>
                            om snel een product toe te voegen.
                        </p>
                        <div className="d-flex justify-content-center">
                            <Button
                                variant="primary"
                                className="mb-3 mr-2"
                                size="sm"
                                onClick={ this.showAddProductModal }
                            >
                                <i className="fas fa-plus mr-2"/>
                                Materiaal toevoegen
                            </Button>
                            <Button
                                variant="secondary"
                                className="mb-3 ml-2"
                                size="sm"
                                onClick={ () => this.setState({ showHistoryModal: true }) }
                            >
                                <i className="fas fa-history mr-2"/>
                                Geschiedenis
                            </Button>
                        </div>
                    </div>
                ) : (
                    <React.Fragment>
                        <div className="d-flex">
                            <div className="flex-grow-1 d-flex flex-row align-items-center">
                                <Button
                                    variant="primary"
                                    className="mr-2"
                                    size="sm"
                                    onClick={ this.showAddProductModal }
                                >
                                    <i className="fas fa-plus mr-2"/>
                                    Materiaal toevoegen
                                </Button>
                                <Button
                                    variant="secondary"
                                    className="mr-2"
                                    size="sm"
                                    onClick={ () => this.setState({ showImportProductsModal: true }) }
                                >
                                    <i className="fas fa-file-import mr-2"/>
                                    Materiaal importeren
                                </Button>
                                { wasteFeeTotal > 0 && (
                                    <div className="ml-3 text-muted d-none d-lg-block">
                                        Afvalbeheerbijdrage: <EuroFormatter price={ wasteFeeTotal }/> excl. btw
                                    </div>
                                )}
                            </div>
                            <div>
                                <Button
                                    variant="secondary"
                                    size="sm"
                                    onClick={ () => this.setState({ showHistoryModal: true }) }
                                >
                                    <i className="fas fa-history mr-2"/>
                                    Geschiedenis
                                </Button>
                            </div>
                        </div>
                        { wasteFeeTotal > 0 && (
                            <div className="mt-2 text-muted d-lg-none">
                                Afvalbeheerbijdrage: <EuroFormatter price={ wasteFeeTotal }/> excl. btw
                            </div>
                        )}
                        { this.state.reservedProducts.length > 0 && (
                            <h4 className="mt-3">Gereserveerd materiaal</h4>
                        )}
                        { this.state.reservedProducts.map((product) => (
                            <InstallationProductCard
                                key={ product.id }
                                product={ product }
                                installationId={ this.props.installationId }
                                onSave={ this.setProductsState }
                                onRemove={ () => this.setState({ showRemoveProductsModal: true, removeProductModalProduct: product }) }
                                onReplace={ () => this.setState({ showReplaceProductModal: true, replaceProductModalProduct: product }) }
                                openTakeProductModal={ () => this.setState({ showTakeProductsModal: true, takeProductModalProduct: product }) }
                            />
                        ))}
                        { takenProducts.length > 0 && (
                            <h4 className="mt-3">Afgenomen materiaal</h4>
                        )}
                        { takenProducts.map((products) => (
                            <InstallationTakenProductCard
                                key={ products[0].id }
                                product={ products[0] }
                                products={ products }
                                installationId={ this.props.installationId }
                                onUndo={ (takenProduct) => this.setState({ showUndoTakenProductsModal: true, undoTakenProductModalProduct: takenProduct }) }
                            />
                        ))}
                    </React.Fragment>
                )}
            </React.Fragment>
        )
    }
}

export default InstallationDetailProducts;
