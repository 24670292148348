import React, {
    useMemo
} from "react";
import {
    Modal
} from "react-bootstrap";
import {
    Annotation
} from "@zandor300/react-apple-mapkitjs";

import AppleMapsMap from "./AppleMapsMap";
import Loading from "../Loading";
import getCenterCoordinate from "../../utilities/getCenterCoordinate";

function AppleMapsMultipleModal({ show, handleClose, title, annotations }) {
    const filteredAnnotations = useMemo(() => {
        return annotations?.filter((annotation) => {
            return annotation.latitude !== null && annotation.longitude !== null;
        });
    }, [annotations]);

    const center = useMemo(() => {
        return getCenterCoordinate(filteredAnnotations);
    }, [filteredAnnotations]);

    return (
        <Modal size="fullscreen" show={ show } onHide={ handleClose }>
            <Modal.Header closeButton>
                <Modal.Title>
                    { title }
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-0">
                { !filteredAnnotations ? (
                    <Loading/>
                ): (
                    <AppleMapsMap
                        autoAdjust
                        latitude={ center.centerLatitude ? center.centerLatitude : 51.994459 }
                        longitude={ center.centerLongitude ? center.centerLongitude : 4.180937 }
                        spanLat={ center.latitudeSpan ? center.latitudeSpan : 0.07 }
                        spanLong={ center.longitudeSpan ? center.longitudeSpan : 0.05 }
                        height="100%"
                        width="100%"
                        initialMapType="hybrid"
                    >
                        { filteredAnnotations?.map((annotation) => (
                            <Annotation
                                key={ annotation.id }
                                id={ annotation.id }
                                longitude={ annotation.longitude }
                                latitude={ annotation.latitude }
                                color={ annotation.color }
                                title={ annotation.title }
                            />
                        ))}
                    </AppleMapsMap>
                )}
            </Modal.Body>
        </Modal>
    )
}

export default React.memo(AppleMapsMultipleModal);
