import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import {
    Alert,
    Button,
    Spinner,
} from "react-bootstrap";
import Loading from "../../../components/Loading";

const LeadDetailAppointment = ({ lead }) => {
    const [eventTypes, setEventTypes] = useState(null);
    const [loading, setLoading] = useState(false);
    const [selectedEventType, setSelectedEventType] = useState(null);
    const [emailContent, setEmailContent] = useState("Beste klant, we willen u graag uitnodigen voor een afspraak.");
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(null)

    const fetchEventTypes = useCallback(async () => {
        try {
            const response = await axios.get("/getAllCalEventTypes");
            const data = response.data;

            if (data.valid) {
                const allEventTypes = data.eventTypes.flatMap(group =>
                    group.eventTypes.map(eventType => ({
                        id: eventType.id,
                        title: eventType.title,
                        slug: eventType.slug,
                        profileSlug: group.profile.slug
                    }))
                );
                setEventTypes(allEventTypes);
            }
        } catch (error) {
            console.error("Error fetching event types:", error);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchEventTypes();
    }, [fetchEventTypes]);

    const handleSelectChange = (event) => {
        setSelectedEventType(event.target.value);
    };

    const handleEmailContentChange = useCallback((event) => {
        setEmailContent(event.target.value);
    }, []);

    const handleSubmit = useCallback(async (event) => {
        event.preventDefault();
        setLoading(true);

        if (!selectedEventType || !emailContent) {
            setError("Selecteer een gebeurtenistype en geef aanvullende details op.");
            setLoading(false)
            return;
        }

        const emailData = {
            leadId: lead.id,
            subject: "Uitnodiging om een fysieke afspraak te maken!",
            content: emailContent,
            selectedEventType
        };

        try {
            const emailResponse = await axios.post("/sendCalEmail", emailData);

            if (emailResponse.data && emailResponse.data.valid === true) {
                setSuccess(true);
                setError(null);
            } else {
                setError("Er ging iets mis bij het versturen van de email. Probeer het later nogmaals.");
            }
        } catch (error) {
            console.error("Error sending email:", error);
            setError("Er ging iets mis bij het versturen van de email. Probeer het later nogmaals.");
        } finally {
            setLoading(false);
        }
    }, [selectedEventType, emailContent, lead]);

    return (
        <div className="card mb-3">
            <div className="card-body">
                {success && (
                    <Alert variant="success">
                        Email verstuurd!
                    </Alert>
                )}
                {error && (
                    <Alert variant="danger">
                        {error}
                    </Alert>
                )}
                <h4>Afspraak</h4>
                <p>Stuur een e-mail naar de klant om een afspraak te maken.</p>
                {!eventTypes ? (
                    <Loading />
                ) : eventTypes.length === 0 ? (
                    <Alert variant="info">
                        Er zijn geen afspraaktypen beschikbaar.
                    </Alert>
                ) : (
                    <div className="form-group">
                        <select
                            className="form-control"
                            value={selectedEventType || ""}
                            onChange={handleSelectChange}
                            disabled={loading}
                        >
                            <option value="" disabled>
                                Kies een afspraaktype
                            </option>
                            {eventTypes.map((eventType) => (
                                <option key={eventType.id} value={eventType.id}>
                                    {eventType.title}
                                </option>
                            ))}
                        </select>
                    </div>
                )}
                {selectedEventType && (
                    <div className="mt-3">
                        <textarea
                            placeholder="Voer de e-mailinhoud in"
                            className="form-control"
                            value={emailContent}
                            onChange={handleEmailContentChange}
                            disabled={loading}
                        />
                        <Button
                            variant="primary"
                            onClick={handleSubmit}
                            className="btn btn-primary mt-3"
                            disabled={loading}
                        >
                            {loading && (
                                <Spinner animation="border" variant="light" size="sm" className="mr-2" />
                            )}
                            Versturen
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
}
export default LeadDetailAppointment;
