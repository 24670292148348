import React from "react";
import axios from "axios";
import {
    Alert,
    Button,
    Modal
} from "react-bootstrap";
import Papa from "papaparse";

import Loading from "../../../../components/Loading";
import numberFormatter from "../../../../components/formatters/NumberFormatter";
import TagPill from "../../../../components/tagPill";

import logo from "../../../../img/esdec-calculator.png";

class InstallationDetailImportProductsModal extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            file: null,
            parsedCSV: null,
            loading: false,
            loadingError: null,
            error: null,
            allProducts: null
        }
        this.onShow = this.onShow.bind(this);
        this.onFileSelect = this.onFileSelect.bind(this);
        this.onSave = this.onSave.bind(this);
        this.fileInputRef = React.createRef();
    }

    onShow() {
        this.setState({ file: null, error: null, loading: false, parsedCSV: null });
        this.getProducts();
    }

    getProducts() {
        this.setState({ allProducts: null, loadingError: null });
        axios.get("/getProducts")
            .then((response) => {
                if(response.data.valid) {
                    this.setState({ allProducts: response.data.data });
                } else {
                    this.setState({ loadingError: "Er ging iets mis. Probeer het later opnieuw. (" + response.data.error + ")" });
                }
            })
            .catch((requestError) => {
                console.error(requestError);
                this.setState({ loadingError: "Er ging iets mis. Probeer het later opnieuw." });
            });
    }

    async onFileSelect() {
        const fileInput = this.fileInputRef.current;
        if(!fileInput) {
            console.warn("No file input found.");
            this.setState({ file: null });
            return;
        }
        if(fileInput.files.length === 0) {
            console.log("No files selected.");
            this.setState({ file: null });
            return;
        }
        const selectedFile = fileInput.files[0];
        this.setState({ file: selectedFile });

        try {
            const csvContent = await this.readFileContent(selectedFile);
            const productData = await this.parseCSVContent(csvContent);
            const productDataWithProducts = this.findProducts(productData, this.state.allProducts);
            console.log(productDataWithProducts);
            this.setState({ parsedCSV: productDataWithProducts });
        } catch(error) {
            console.error(error);
            this.setState({ error: "Er ging iets fout bij het laden van het bestand." });
        }
    }

    readFileContent(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.addEventListener("load", (event) => {
                resolve(event.target.result);
            }, false);
            reader.addEventListener("error", (event) => {
                reject(event.target.error);
            }, false);
            reader.readAsText(file);
        });
    }

    parseCSVContent(fileContent) {
        return new Promise((resolve, reject) => {
            Papa.parse(fileContent, {
                header: true,
                skipEmptyLines: true,
                complete: (results) => {
                    resolve(this.parseEsdecCSV(results.data));
                },
                error: (error) => {
                    reject(error);
                }
            });
        });
    }

    parseEsdecCSV(csvData) {
        return csvData.map((row) => {
           return {
               sku: row["Artikelnr."],
               amount: row["Aantal"],
               name: row["Omschrijving"]
           }
        });
    }

    findProducts(productData, availableProducts) {
        return productData.map((productDatum) => {
            const foundProduct = availableProducts.find((product) => {
                if(!product.sku) {
                    return false;
                }
                return productDatum.sku === product.sku.toLowerCase().replace("-", "");
            });
            return {
                ...productDatum,
                product: foundProduct
            }
        }).sort((row1, row2) => {
            if(row1.product && !row2.product) { return -1; }
            if(!row1.product && row2.product) { return 1; }
            return 0;
        });
    }

    onSave() {
        const {
            parsedCSV
        } = this.state;
        this.setState({ error: null });
        if(!parsedCSV) {
            this.setState({ error: "Geen CSV geupload." });
            return;
        }
        let productsAmount = {};
        parsedCSV.forEach((row) => {
            if(!row.product) {
                return;
            }
            productsAmount[row.product.id] = row.amount;
        });
        if(Object.keys(productsAmount).length === 0) {
            this.setState({ error: "Geen producten gevonden om toe te voegen." });
            return;
        }
        // Format: productId:amount,productId:amount
        const productsString = Object.entries(productsAmount).map(([productId, amount]) => `${productId}:${amount}`).join(",");
        this.setState({ loading: true });
        axios.post("/addInstallationProductBulk", {
            installationId: this.props.installationId,
            products: productsString
        })
            .then((response) => {
                if(response.data.valid) {
                    this.props.onSave(response.data.reserved, response.data.taken);
                    this.props.handleClose();
                } else {
                    this.setState({ loading: false, error: "Er ging iets fout bij het toevoegen van het materiaal. (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                this.setState({ error: "Er ging iets fout bij het toevoegen van het materiaal." });
                console.error(error);
            });
    }

    render() {
        const {
            show,
            handleClose
        } = this.props;
        const {
            loading,
            error,
            parsedCSV,
            allProducts
        } = this.state;
        return (
            <Modal show={ show } onShow={ this.onShow } onHide={ handleClose } size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        Producten importeren
                        <span className="badge badge-secondary badge-pill ml-2">Beta</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    { error && (
                        <Alert variant="danger">{ error }</Alert>
                    )}
                    { !allProducts ? (
                        <Loading/>
                    ) : !parsedCSV ? (
                        <React.Fragment>
                            { error && (
                                <Alert variant="danger">{ error }</Alert>
                            )}
                            <div className="d-flex justify-content-center mb-2">
                                <img src={ logo } alt="Esdec Calculator" style={{ maxWidth: "150px" }}/>
                            </div>
                            <p>
                                Download een CSV bestellijst van Esdec Calculator en selecteer deze hier om de producten
                                over te nemen.
                            </p>
                            <div className="custom-file">
                                <input
                                    id="fileinput"
                                    className="custom-file-input"
                                    ref={ this.fileInputRef }
                                    type="file"
                                    accept=".csv"
                                    onChange={ this.onFileSelect }
                                />
                                <label className="custom-file-label" htmlFor="fileinput">
                                    Selecteer een bestand
                                </label>
                            </div>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            { parsedCSV.map((product, index) => (
                                <div className="card mb-2" key={ index }>
                                    <div className="card-body d-flex flex-row">
                                        { product.product ? (
                                            <div className="flex-grow-1 d-flex flex-row">
                                                <div className="text-muted mr-2">
                                                    <h5 className="m-0">
                                                        { numberFormatter({ number: product.amount }) }x{" "}
                                                    </h5>
                                                </div>
                                                <div className="flex-grow-1">
                                                    <h5 className="m-0">
                                                        { product.product.brand.name } { product.product.name }
                                                    </h5>
                                                </div>
                                                <div>
                                                    <TagPill color={ product.product.type.color }>
                                                        { product.product.type.name }
                                                    </TagPill>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="flex-grow-1 d-flex flex-row text-danger align-items-center">
                                                <div className="mr-2">
                                                    <h5 className="m-0">
                                                        { numberFormatter({ number: product.amount }) }x{" "}
                                                    </h5>
                                                </div>
                                                <div className="flex-grow-1">
                                                    <h5 className="m-0">
                                                        { product.name }
                                                    </h5>
                                                </div>
                                                <div className="d-flex flex-column align-items-end">
                                                    <div>
                                                        Niet gevonden
                                                    </div>
                                                    <div>
                                                        { product.sku }
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ))}
                            <Alert variant="warning" className="mb-0">
                                Niet gevonden producten worden overgeslagen.
                            </Alert>
                        </React.Fragment>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={ handleClose } disabled={ loading }>
                        Annuleer
                    </Button>
                    <Button variant="primary" onClick={ this.onSave } disabled={ loading || !parsedCSV }>
                        Toevoegen
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default InstallationDetailImportProductsModal;
